@import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@400;700&display=swap');

html {
  position: relative;
  width: 100%;
  height: 100%;
  background: #000000;
  color: #fff;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Public Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
}

h1,
h2 {
  font-size: 24px;
  margin: 0 0 24px;
  padding: 0;
  letter-spacing: -0.05rem;
}

a {
  color: rgb(0, 100, 200);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a:visited {
  color: rgb(0, 80, 160);
}

label {
  display: block;
}

input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  -webkit-padding: 0.4em 0;
  padding: 0.4em;
  margin: 0 0 0.5em 0;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 2px;
}

input:disabled {
  color: #ccc;
}

button {
  color: #333;
  background-color: #f4f4f4;
  outline: none;
}

button:disabled {
  color: #999;
}

button:not(:disabled):active {
  background-color: #ddd;
}

button:focus {
  border-color: #666;
}

.green {
  color: #22bb88;
}

.red {
  color: #b52c50;
}

h2 {
  font-size: 64px;
  line-height: 1;
  margin-bottom: 8px;
}

hr.main {
  border-top: 2px solid #fff;
  margin-top: 32px;
  margin-bottom: 16px;
}

hr {
  width: 100%;
  height: 0;
  border: none;
  border-top: 1px solid #333;
  margin-top: 16px;
  margin-bottom: 16px;
}

ul {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  list-style: none;

  li:nth-of-type(1) {
    line-height: 1.5;
  }

  li:nth-of-type(2) {
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: bold;
  }

  &.hero li:nth-of-type(2) {
    font-size: inherit;
    font-weight: inherit;
  }

  &.hero:nth-of-type(2) {
    margin-top: 8px;
  }

  &.hero li.count {
    font-weight: bold;
  }
}
